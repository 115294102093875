<template>
    <div class="invoice">
        <div class="line"></div>
        <div class="imgBox">
            <img :src="imgUrl" />
            <Button class="btn" plain block color='#2b72ff' @click="show=true">发送至邮箱</Button>
        </div>
        <van-dialog v-model="show" title="请输入邮箱" show-cancel-button  :before-close='sendEmail' >
             <Field v-model.trim="email" label="" placeholder="请输入邮箱地址" />
        </van-dialog>
    </div>
</template>

<script>
import {Button,Dialog ,Field,Notify,Toast} from "vant";
import { emailInvoice } from "@/api/my.js"

    export default {
        name:'previewInvoice',
        components:{
            Button,Field,Toast,
            [Dialog.Component.name]: Dialog.Component,
             [Notify.Component.name]: Notify.Component,
        },
        data(){
            return{
                id:'',
                imgUrl:'',
                email:'',
                show:false
            }
        },
        created(){
            let {id,imgUrl}=this.$route.query;
            this.id=id;
            this.imgUrl=imgUrl;
        },
        methods:{
            sendEmail(action, done){
                if(action=='confirm'){
                    let pattern=/^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}$/;
                    if(!this.email){
                        Notify({ type: 'warning', message: '请输入邮箱地址' });
                        done(false);
                        return false
                    }
                    if(!pattern.test(this.email)){
                        Notify({ type: 'warning', message: '请输入正确的邮箱地址' });
                        done(false)
                        return false
                    }else{
                        done();
                        emailInvoice({email:this.email,id:this.id}).then(res=>{
                            if(res.status=='S'){
                                Toast.success('发送成功！');
                            }
                        })
                    }
                }else{
                    done();
                }

            }
        }
    }
</script>

<style lang="less" scoped>
::v-deep.van-dialog{
    .van-dialog__confirm{
        color: #2b72ff;
    }
}
.invoice{
    width: 100vw;
    height: 100vh;
    background: #f5f5f5;
    .line{
        height: 0.12rem;
        background: #f5f5f5;
    }
    .imgBox{
        background: #fff;
        padding: 0.2rem 0.12rem 0.4rem;
        img{
            width: 100%;
        }
        .btn{
            border-radius: 5px;
            height: 0.42rem;
            margin-top: 0.3rem;
        }
    }
}
</style>
